export function setupAudioWavesEffect(timeline, audio) {
  const canvas = timeline.querySelector("canvas");
  let size = null;

  new ResizeObserver(([{ contentRect }]) => {
    const { width, height } = contentRect;
    size = [window.devicePixelRatio * width, window.devicePixelRatio * height];
    canvas.width = size[0];
    canvas.height = size[1];
  }).observe(timeline);

  const ctx = canvas.getContext("2d");

  let waveScale = 0;
  let lastTime = 0;

  const render = (time) => {
    requestAnimationFrame(render);
    const delta = time - lastTime;
    lastTime = time;

    if (!size) return;
    const [w, h] = size;

    ctx.clearRect(0, 0, w, h);

    const screenSm = window.innerWidth >= 640;

    ctx.lineWidth = (screenSm ? 6 : 3) * window.devicePixelRatio;
    ctx.strokeStyle = "#0078B8";
    const stepSize = 16;
    const resolution = Math.ceil(w / stepSize);
    const t = time / 1000;
    const waveExtent = 0.5 * h - ctx.lineWidth / 2;
    const waveCount = 3;

    waveScale = Math.min(
      1,
      waveScale + delta * 0.015 * (audio.paused ? -waveScale : 1 - waveScale)
    );

    for (let i = 0; i < waveCount; i++) {
      ctx.beginPath();
      ctx.moveTo(-stepSize, h / 2);
      for (let j = 0; j <= resolution; j++) {
        const x = j * stepSize;
        const p = Math.min(1, x / w);
        const amp =
          1 - Math.pow(Math.sin(Math.PI * (p - 0.5)), screenSm ? 12 : 4);
        ctx.lineTo(
          x,
          h / 2 +
            waveExtent *
              amp *
              waveScale *
              Math.sin(
                ((screenSm ? 0.004 : 0.006) * x +
                  -0.2 * (t - 10 * (i / waveCount))) *
                  Math.PI
              )
        );
      }
      ctx.stroke();
    }
  };

  render(0);
}
