import "./style.css";

Array.from(document.querySelectorAll(".post-archive")).forEach(
  (archiveElem) => {
    const filterOptions = archiveElem.querySelector(".post-archive--filter");

    Array.from(
      archiveElem.querySelectorAll(".post-archive--mediatype-btn")
    ).forEach((btn) => {
      const mediaType = btn.getAttribute("data-slug");

      btn.addEventListener("click", () => {
        filterOptions.dispatchEvent(new CustomEvent("expand"));
        archiveElem.dispatchEvent(
          new CustomEvent("toggle-mediatype", { detail: mediaType })
        );
      });

      archiveElem.addEventListener("change", ({ detail: { mediaTypes } }) => {
        btn.classList.toggle(
          "active",
          mediaTypes.length && !mediaTypes.includes(mediaType)
        );
      });
    });
  }
);
