import "./style.css";
import { Swiper } from "../../swiper";

export const titleToSlug = (title) =>
  title
    .toLowerCase()
    .trim()
    .replace(/[ _/.]/g, "-")
    .replace("ä", "ae")
    .replace("ö", "oe")
    .replace("ü", "ue");

Array.from(document.querySelectorAll(".partner-enum")).forEach((wrapper) => {
  const buttons = Array.from(
    wrapper.querySelectorAll(".partner-enum--logo-grid button")
  );
  const [leftBtn, rightBtn] = Array.from(
    wrapper.querySelectorAll(".partner-enum--arrows button")
  );
  const items = Array.from(wrapper.querySelectorAll(".swiper-slide"));

  const slugs = items.map((item) =>
    titleToSlug(item.getAttribute("data-title"))
  );

  const getSlugFromUrl = () =>
    window.location.hash?.replace(/^#/, "") || slugs[0];
  const getSlideIdFromUrl = () => slugs.indexOf(getSlugFromUrl());

  const updateLocationHash = (id) => {
    window.location.hash = `#${slugs[id]}`;
  };

  const swiper = new Swiper(wrapper.querySelector(".swiper"), {
    allowTouchMove: false,
    autoHeight: true,
    speed: 500,
    spaceBetween: 128,
    initialSlide: getSlideIdFromUrl(),
    navigation: {
      nextEl: rightBtn,
      prevEl: leftBtn,
    },
  });

  swiper.on("slideChange", () => {
    updateLocationHash(swiper.activeIndex);
  });

  buttons.forEach((btn) => {
    const itemId = btn.getAttribute("data-item-id");
    btn.addEventListener("click", () => {
      updateLocationHash(itemId);
    });
  });

  const updateButtons = () => {
    const btn = buttons[swiper.activeIndex];
    buttons.forEach((btn) => {
      btn.removeAttribute("data-active");
    });
    btn.setAttribute("data-active", "1");
  };

  window.addEventListener("popstate", () => {
    swiper.slideTo(getSlideIdFromUrl());
    updateButtons();
  });

  updateButtons();
  updateLocationHash(swiper.activeIndex);
});
