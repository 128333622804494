import { Swiper } from "../../swiper";

document.querySelectorAll(".person-swiper").forEach((el) => {
  new Swiper(el, {
    loop: true,
    spaceBetween: 24,
    allowTouchMove: false,
    slidesPerView: 1.5,
    touchEventsTarget: "container",
    autoplay: true,
    speed: 1000,
    breakpoints: {
      400: {
        slidesPerView: 2.5,
      },
      640: {
        slidesPerView: 2.5,
      },
      768: {
        slidesPerView: 3.5,
      },
      1024: {
        slidesPerView: 4.5,
      },
    },
  });
});
