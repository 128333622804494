export function createBuffer(gl, target, data) {
  let value = data;

  const buffer = gl.createBuffer();

  function bind() {
    gl.bindBuffer(target, buffer);
  }

  function set(data) {
    value = data;
    gl.bufferData(target, data, gl.STATIC_DRAW);
  }

  bind();
  set(data);

  return { bind, set, get: () => value };
}
