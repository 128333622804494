import { parseGlsl } from "./shader";
import { createBuffer } from "./buffer";

import vertexGlsl from "raw-loader!glslify-loader!./shader.vert";
import fragmentGlsl from "raw-loader!glslify-loader!./shader.frag";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16) / 255,
        parseInt(result[2], 16) / 255,
        parseInt(result[3], 16) / 255,
      ]
    : null;
}

Array.from(document.querySelectorAll(".color-noise")).forEach((wrapper) => {
  const canvas = wrapper.querySelector("canvas");
  const gl = canvas.getContext("webgl");
  if (!gl) return;

  const colors = ["a", "b"].map((name) => [
    name,
    hexToRgb(wrapper.getAttribute(`data-color-${name}`)),
  ]);
  let viewProjection = [1, 1];

  gl.getExtension("OES_element_index_uint");

  const vertices = new Float32Array(3 * 4);
  vertices[0] = vertices[7] = vertices[9] = vertices[10] = -1;
  vertices[1] = vertices[3] = vertices[4] = vertices[6] = 1;
  const vertexBuffer = createBuffer(gl, gl.ARRAY_BUFFER, vertices);

  const indices = new Uint16Array(6);
  indices[0] = indices[3] = 0;
  indices[1] = indices[5] = 2;
  indices[2] = 1;
  indices[4] = 3;
  const indexBuffer = createBuffer(gl, gl.ELEMENT_ARRAY_BUFFER, indices);

  const vertexShader = parseGlsl(gl, vertexGlsl, gl.VERTEX_SHADER);
  const fragmentShader = parseGlsl(gl, fragmentGlsl, gl.FRAGMENT_SHADER);

  const program = gl.createProgram();

  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);

  const loc = gl.getAttribLocation(program, "position");
  gl.vertexAttribPointer(loc, 3, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(loc);

  new ResizeObserver(([{ contentRect }]) => {
    const { width, height } = contentRect;

    viewProjection = width > height ? [1, height / width] : [width / height, 1];

    const dw = window.devicePixelRatio * width;
    const dh = window.devicePixelRatio * height;
    canvas.width = dw;
    canvas.height = dh;
    gl.viewport(0, 0, dw, dh);
  }).observe(wrapper);

  const render = (timeMs) => {
    requestAnimationFrame(render);

    gl.useProgram(program);

    gl.uniform2fv(
      gl.getUniformLocation(program, "view_projection"),
      viewProjection
    );
    gl.uniform1f(gl.getUniformLocation(program, "time"), timeMs / 1000);
    colors.forEach(([name, value]) => {
      gl.uniform3fv(gl.getUniformLocation(program, `color_${name}`), value);
    });

    vertexBuffer.bind();
    indexBuffer.bind();

    gl.drawElements(gl.TRIANGLES, indices.length, gl.UNSIGNED_SHORT, 0);
  };

  render(0);
});
