import "./style.css";
import "./mediatypes";
import "./categories";

Array.from(document.querySelectorAll(".post-archive--filter")).forEach(
  (wrapper) => {
    const btn = wrapper.querySelector("button");

    let expanded = false;
    const setExpanded = (value) => {
      expanded = value;
      if (expanded) wrapper.setAttribute("data-expanded", "1");
      else wrapper.removeAttribute("data-expanded");
    };

    wrapper.addEventListener("expand", () => setExpanded(true));
    btn.addEventListener("click", () => setExpanded(!expanded));
  }
);
