import "./style.css";

Array.from(document.querySelectorAll(".post-archive")).forEach(
  (archiveElem) => {
    Array.from(
      archiveElem.querySelectorAll(".post-archive--category-btn")
    ).forEach((btn) => {
      const categoryTerm = btn.getAttribute("data-slug");

      btn.addEventListener("click", () => {
        archiveElem.dispatchEvent(
          new CustomEvent("toggle-category", { detail: categoryTerm })
        );
      });

      archiveElem.addEventListener(
        "change",
        ({ detail: { categoryTerms } }) => {
          btn.classList.toggle(
            "inactive",
            categoryTerms.length && !categoryTerms.includes(categoryTerm)
          );
        }
      );
    });
  }
);
