import "./filter-options";

Array.from(document.querySelectorAll(".post-archive")).forEach((wrapper) => {
  const posts = Array.from(wrapper.querySelectorAll(".post-enumeration--item"))
    .map((elem) => {
      const article = elem.querySelector("article");
      return (
        article && {
          elem,
          categoryTerm: article.getAttribute("data-category-term"),
          mediaTypes: article.getAttribute("data-mediatypes")?.split(",") || [],
        }
      );
    })
    .filter(Boolean);
  if (!posts.length) return;

  const postWrapper = posts[0].elem.parentElement;

  const state = {
    mediaTypes: [],
    categoryTerms: [],
  };

  wrapper.addEventListener("toggle-mediatype", ({ detail: mediaType }) => {
    const id = state.mediaTypes.indexOf(mediaType);

    if (id > -1) state.mediaTypes.splice(id, 1);
    else state.mediaTypes.push(mediaType);

    wrapper.dispatchEvent(new CustomEvent("change", { detail: { ...state } }));
  });

  wrapper.addEventListener("toggle-category", ({ detail: categoryTerm }) => {
    const id = state.categoryTerms.indexOf(categoryTerm);

    if (id > -1) state.categoryTerms.splice(id, 1);
    else state.categoryTerms.push(categoryTerm);

    wrapper.dispatchEvent(new CustomEvent("change", { detail: { ...state } }));
  });

  wrapper.addEventListener("change", () => {
    Array.from(postWrapper.children).forEach((child) => child.remove());

    let filteredPosts = posts;

    if (state.mediaTypes.length)
      filteredPosts = filteredPosts.filter(({ mediaTypes }) =>
        state.mediaTypes.some((t) => mediaTypes.includes(t))
      );

    if (state.categoryTerms.length)
      filteredPosts = filteredPosts.filter(({ categoryTerm }) =>
        state.categoryTerms.includes(categoryTerm)
      );

    filteredPosts.forEach(({ elem }) => {
      postWrapper.appendChild(elem);
    });
  });
});
