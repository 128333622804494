import { secondsToString } from "./seconds-to-string";
import "./style.css";
import { setupAudioWavesEffect } from "./waves";

Array.from(document.querySelectorAll(".audio-player")).forEach((wrapper) => {
  const audio = wrapper.querySelector("audio");
  const playButton = wrapper.querySelector(".audio-player--play-btn");
  const cursor = wrapper.querySelector(".audio-player--cursor");
  const timeline = wrapper.querySelector(".audio-player--timeline");
  const timeInfo = wrapper.querySelector(".audio-player--time-info");

  setupAudioWavesEffect(timeline, audio);

  const togglePlay = () => {
    if (audio.paused) audio.play();
    else audio.pause();
  };

  playButton.addEventListener("click", togglePlay);

  window.addEventListener("keypress", (evt) => {
    if (evt.key == " ") {
      evt.preventDefault();
      togglePlay();
    }
  });

  const updateCursor = (x) => {
    cursor.style.left = `${100 * x}%`;
  };

  const mouseToTime = (mouseX) => {
    const { left, width } = timeline.getBoundingClientRect();
    const x = Math.min(0.999, Math.max(0, mouseX - left) / width);
    updateCursor(x);
    if (!isNaN(audio.duration)) audio.currentTime = x * audio.duration;
  };

  let scrubState = null;

  const setScrubState = (state) => {
    if (state?.wasPlaying) wrapper.setAttribute("data-was-playing", "1");
    else wrapper.removeAttribute("data-was-playing");
    scrubState = state;
  };

  const startScrub = (clientX) => {
    if (scrubState) return;
    mouseToTime(clientX);
    setScrubState({ wasPlaying: !audio.paused });
    if (!audio.paused) audio.pause();
  };

  const endScrub = () => {
    if (!scrubState) return;
    if (scrubState.wasPlaying && audio.paused) audio.play();
    setScrubState(null);
  };

  timeline.addEventListener("mousedown", (evt) => {
    startScrub(evt.clientX);
  });
  timeline.addEventListener("touchstart", (evt) => {
    startScrub(evt.changedTouches[0].clientX);
  });

  window.addEventListener("mouseup", endScrub);
  window.addEventListener("touchend", endScrub);

  window.addEventListener("mousemove", (evt) => {
    if (!scrubState) return;
    evt.preventDefault();
    mouseToTime(evt.clientX);
  });
  timeline.addEventListener("touchmove", (evt) => {
    if (scrubState) mouseToTime(evt.changedTouches[0].clientX);
  });

  audio.addEventListener("timeupdate", () => {
    timeInfo.innerHTML = secondsToString(Math.floor(audio.currentTime));
    if (isNaN(audio.duration) || scrubState) return;
    updateCursor(Math.min(1, audio.currentTime / audio.duration));
  });

  audio.addEventListener("ended", () => {
    wrapper.removeAttribute("data-playing");
  });

  audio.addEventListener("pause", () => {
    wrapper.removeAttribute("data-playing");
  });

  audio.addEventListener("play", () => {
    wrapper.setAttribute("data-playing", "1");
  });
});
