function createShapeWiggler(shapes) {
  let playing = false;
  let lastUpdate = 0;

  function update() {
    if (!playing) return;

    const now = Date.now();
    if (now - lastUpdate > 1000 / 5) {
      lastUpdate = now;
      shapes.forEach((shape) => {
        shape.setAttribute(
          "transform",
          `scale(${1 - 0.1 * Math.random()}, ${
            1 - 0.1 * Math.random()
          }) translate(${2 * (2 * Math.random() - 1)}, ${
            1.2 * (2 * Math.random() - 1)
          }) rotate(${3 * (2 * Math.random() - 1)})`
        );
      });
    }

    requestAnimationFrame(update);
  }

  return {
    play() {
      if (playing) return;
      playing = true;
      update();
    },
    pause() {
      playing = false;
    },
  };
}

Array.from(document.querySelectorAll(".header-nav--main")).forEach(
  (wrapper) => {
    wrapper.setAttribute("data-ready", "1");

    const items = Array.from(wrapper.querySelectorAll("li"));
    const activeItem = wrapper.querySelector("li.header-nav--main-item-active");
    if (!items.length) return;

    const links = Array.from(wrapper.querySelectorAll("li > a"));

    const clearItemStates = () =>
      items.forEach((item) => {
        item.removeAttribute("data-shapes-visible");
      });

    activeItem?.setAttribute("data-shapes-visible", "1");
    let activeItemWiggler = null;

    items.forEach((item, i) => {
      const link = links[i];

      link.addEventListener("mouseover", () => {
        clearItemStates();
        item.setAttribute("data-shapes-visible", "1");
      });
      link.addEventListener("mouseout", () => {
        clearItemStates();
        activeItem?.setAttribute("data-shapes-visible", "1");
      });

      const wiggler = createShapeWiggler(
        Array.from(link.querySelectorAll("svg ellipse"))
      );
      if (item.classList.contains("header-nav--main-item-active")) {
        activeItemWiggler = wiggler;
        return;
      }

      link.addEventListener("mouseover", () => {
        wiggler.play();
        activeItemWiggler?.pause();
      });
      link.addEventListener("mouseout", () => {
        wiggler.pause();
        activeItemWiggler?.play();
      });
    });

    activeItemWiggler?.play();
  }
);
